<template>
    <ServerListPage/>
</template>

<script setup>
import ServerListPage from "~/pages/rank/[[page]].vue";

useServerHead({
    link: [
        {
            rel: "canonical",
            href: "https://www.minecraftiplist.com",
        },
    ],
});
</script>